import React from 'react'
import { useState, useEffect } from 'react'
import { Box,Typography } from '@mui/material'
import { Videos } from './'
import { fetch } from '../utils/fetch'
import { useParams } from 'react-router-dom'

const SearchFeed = () => {

  const [videos, setvideos] = useState([])
  const { searchTerm } = useParams();

  useEffect(() => {
    fetch(`search?part=snippet&q=${searchTerm}`).then((data)=> setvideos(data.items))
  }, [searchTerm]);
  
  return (
    <Box p={2} sx={{ overflow:'auto', height:'90vh', flex:2 }}>
      <Typography variant='h4' fontWeight='bold' mb={2} sx={{ color:'white' }}>
        Search Results for : <span style={{ color:'#F31503' }}>{searchTerm}</span>
      </Typography>
      <Box display='flex'>
        <Box sx={{mr: { lg:'130px' }}} />
          <Videos videos={videos} />
      </Box>
    </Box>
  )
}

export default SearchFeed